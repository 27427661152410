<template>
	<div class="page container">

		<div class="page__info">
			<div class="page__info--title">
				Purchases
			</div>
		</div>

		<div>
			<table-view
				:table="getTable"
				:pagination="pagination"
				:totalItems="totalItems"
				@search="onSearch"
				@paginate="onPaginate"
			/>
		</div>
	</div>
</template>

<script>
	import TableView     from '@/components/ui/views/tableView/TableView';
	import api           from '@/services/api';
	import dayjs         from 'dayjs';
	import relativeTime  from 'dayjs/plugin/relativeTime';

	dayjs.extend(relativeTime);

	export default {
		layout: 'v2default',
		metaInfo: {
			title: 'Purchases'
		},
		components: {
			TableView
		},
		data: () => ({
			enrolments: [],
			searchString: '',
			pagination: {
				perPage: 20,
				currentPage: 1,
				totalPages: 0
			},
			totalItems: 0
		}),
		computed: {
			getUserId () {
				return this.$route.params.userId;
			},
			getUser () {
				if (!this.user ||
						!this.user.id) {
					return false;
				}
				return this.user;
			},
			getBreadcrumbRoute () {
				if (!this.getUserId) {
					return false;
				}
				return `/admin/users/${this.getUserId}`;
			},
			getTable () {
				const headings = [
					'Title',
					'Teacher',
					'Cost',
					'Date',
					'Active'
				];
				if (!this.getEnrolments) {
					return {
						headings
					};
				}
				return {
					headings,
					rows: this.getEnrolments.map((enrolment) => {
						return {
							route: `/admin/users/${this.getUserId}/purchases/${enrolment.enrolmentId}`,
							columns: [
								enrolment.courseTitle,
								enrolment.teacherName,
								enrolment.cost,
								this.getJoinDateString(enrolment.joinDate),
								enrolment.activated ? 'Yes' : 'No'
							]
						};
					})
				};
			},
			getEnrolments () {
				if (!this.enrolments ||
						!this.enrolments.length) {
					return false;
				}
				return this.enrolments;
			},
			getBreadcrumbs () {
				return [
					{
						path: '/admin',
						text: 'Admin'
					},
					{
						path: '/admin/users',
						text: 'Users'
					},
					{
						path: `/admin/users/${this.getUserId}`,
						text: 'User'
					},
					{
						path: `/admin/users/${this.getUserId}/purchases`,
						text: 'Purchases'
					}
				].filter(Boolean);
			}
		},
		created: function () {
			this.setEnrolments();
		},
		methods: {
			onSearch (value) {
				this.searchString = value;
				this.setEnrolments();
			},
			onPaginate (value) {
				this.pagination.currentPage = value;
				this.setEnrolments();
			},
			getJoinDateString (joinDate) {
				if (!joinDate) {
					return '--';
				}
				return dayjs().to(joinDate);
			},
			async setEnrolments () {
				const response = await api.admin.getEnrolmentsByUserId({
					userId: this.getUserId,
					include: 'metadata',
					searchString: this.searchString,
					per: this.pagination.perPage,
					page: this.pagination.currentPage
				});
				if (!response) {
					this.$store.commit('ui/showError');
					return false;
				}
				this.totalItems = response.meta.totalItems;
				this.pagination.totalPages = response.meta.numPages;
				this.pagination.currentPage = response.meta.page;
				this.enrolments = response.results;
				this.$store.commit('ui/setBreadcrumbs', {
					breadcrumbs: this.getBreadcrumbs
				});
				this.$store.commit('ui/setLoadingIsHidden');
			}
		}
	};

</script>
